<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Appointments/List</h4>
          <div>
            <router-link :to="can('appointment-create') ? {name:'appAppointmentCreate'} : '#'">
                <span :title="can('appointment-create') ? 'Create' : 'Create Forbidden'"
                      class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>

        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Show Per Page</label>
                  <VueMultiselect v-model="selectedPagination" :options="paginationOptions" :close-on-select="true"
                                  label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>State</label>
                  <VueMultiselect v-model="selectedState" class="" :options="stateOptions" :close-on-select="true"
                                  label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Appointment Type</label>
                  <VueMultiselect v-model="selectedAppointmentType" class="" :options="appointmentTypeOptions"
                                  :allow-empty="false" :close-on-select="true" label="name" track-by="value"
                                  :show-labels="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Status</label>
                  <VueMultiselect v-model="selectedStatus" class="" :options="appointmentStatusOptions"
                                  :allow-empty="false" :close-on-select="true" label="name" track-by="value"
                                  :show-labels="false"/>
                </div>
              </div>
              <!-- <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Service Date From</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Service Date To</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div> -->
              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Time Range</label>
                  <VueMultiselect v-model="selectedTimeRange" class="" :options="timeRangeOptions" :allow-empty="false"
                                  :close-on-select="true" label="name" track-by="name" :show-labels="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Service</label>
                  <VueMultiselect v-model="selectedService" class="" :options="serviceOptions" :allow-empty="false"
                                  :close-on-select="true" label="name" track-by="name" :show-labels="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Appointment Technician</label>
                  <VueMultiselect v-model="selectedTechnician" :options="technicianOptions" :close-on-select="true"
                                  label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Appointment Reference</label>
                  <input v-model="getAppointmentsParams.reference" class="form-control " type="text"
                         placeholder="Enter Reference">
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Customer/Address Search</label>
                  <input v-model="getAppointmentsParams.where_has_customer_user_or_customer_address_query"
                         class="form-control " type="text" placeholder="Search by Customer or Address">
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label>Service Date Range (From ~ To)</label>
                    <label class="cursor-pointer" title="Clear service date range filter"
                           @click="clearServiceDateRange()">Clear</label>
                  </div>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker is-range v-model="serviceDateRange"
                                  :masks="masks"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control"
                                   :value="(serviceDateRange?.start?.length  && serviceDateRange?.end?.length )  ? serviceDateRange?.start +' To '+serviceDateRange.end : ''"
                                   style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label>Creation Date Range (From ~ To)</label>
                    <label class="cursor-pointer" title="Clear creation date range filter"
                           @click="clearCreationDateRange()">Clear</label>
                  </div>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker is-range v-model="creationDateRange" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control"
                                   :value="(creationDateRange?.start?.length  && creationDateRange?.end?.length )  ? creationDateRange?.start +' To '+creationDateRange.end : ''"
                                   style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">

            </div>
            <div class="row justify-content-start">
              <div class="col-12 col-sm-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>Appointment Panel</label>
                  <VueMultiselect v-model="selectedPanel" class="" :options="panelOptions"
                                  :allow-empty="false" :close-on-select="true" label="name" track-by="value"
                                  :show-labels="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-2 ">
                <div class="form-group">
                  <label style="visibility: hidden">Filter</label>
                  <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="applyAppointmentFilter(null)">Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 5%">SL</th>
                    <th class="position-relative" style="width: 20%">Customer</th>
                    <th class="position-relative" style="width: 10%">Service</th>
                    <th class="position-relative" style="width: 10%">Panel</th>
                    <th class="position-relative" style="width: 15%">Job Location</th>
                    <th class="position-relative" style="width: 10%">Technician</th>
                    <th class="position-relative" style="width: 5%">Time Interval</th>
                    <th class="position-relative" style="width: 5%">Status</th>
                    <th class="position-relative text-right" style="width: 15%">Price</th>
                    <th class="position-relative text-right" style="width: 5%">Action</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="(appointment, index) in appointments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span>
                          <i class="bx"
                             :class="appointment.type === 'Home' ? 'bx-home text-warning' : 'bxs-business text-success'"></i>
                        </span>
                        <router-link title="Click to view customer details"
                                     :to="{name:'appCustomerView',params: { id: appointment.customer.id }}"
                                     v-if="appointment.customer && appointment.customer.user">
                          {{ `${appointment.customer.user.first_name} ${appointment.customer.user.last_name}` }}
                        </router-link>
                      </div>
                      <div>

                        <small @click="openOrderDetailsModal(appointment.order.id)"
                               :title="(appointment?.order?.id) ? 'click to view order' : ''"
                               v-if="(appointment?.order?.id)"
                               :class="(appointment?.order?.id) ? 'text-danger mr-1 cursor-pointer' :  ''">
                          Reference: #{{ appointment.reference }}
                        </small>
                        <small v-else class="">
                          Reference: #{{ appointment.reference }}
                        </small>
                        <small> <i title="click to view order" @click="openOrderDetailsModal(appointment.order.id)"
                                   v-if="appointment?.order?.id"
                                   class="bx bx-link text-danger cursor-pointer bx-tada"></i></small>
                      </div>
                      <div>
                        <small>
                          Service date : {{ `${appointment.date} ${appointment.time}` }}
                        </small>
                      </div>
                      <div>
                        <small>
                          Creation date : {{ appointment.created_at ? appointment.created_at : '-' }}
                        </small>
                      </div>

                    </td>
                    <td>
                      {{ appointment.service && appointment.service.name ? appointment.service.name : '-' }}
                    </td>
                    <td>
                      <div v-if="appointment?.appointmentCreator?.panel">
                        <span class="text-success"
                              v-if="appointment.appointmentCreator.panel === 'Online Appointment Form'">{{
                            appointment?.appointmentCreator?.panel
                          }}</span>
                        <span class="text-primary"
                              v-if="appointment.appointmentCreator.panel === 'System Web-App'">{{
                            appointment?.appointmentCreator?.panel
                          }}</span>
                        <span class="text-info"
                              v-if="appointment.appointmentCreator.panel === 'Technician Mob-App'">{{
                            appointment?.appointmentCreator?.panel
                          }}</span>
                        <span class="text-secondary" v-if="appointment.appointmentCreator.panel === 'Customer Web-App'">{{
                            appointment?.appointmentCreator?.panel
                          }}</span>
                        <span class="text-danger" v-if="appointment.appointmentCreator.panel === 'Partner Mob-App'">{{
                            appointment?.appointmentCreator?.panel
                          }}</span>
                      </div>
                      <span v-else>-</span>

                    </td>
                    <td>
                      <div v-if="appointment.preference === 'On-Site'">
                        <span v-if="appointment.address">
                          {{
                            buildAddress(appointment.address)
                          }}
                        </span>
                      </div>
                      <span v-else class="badge badge-pill">
                        {{ appointment.preference }}
                      </span>
                    </td>
                    <td>
                      <div v-if="appointment?.technicianAppointment?.id">
                        {{
                          `${appointment.technicianAppointment.technician.user.first_name} ${appointment.technicianAppointment.technician.user.last_name}`
                        }}
                        <span class="badge badge-pill badge-danger"
                              v-if="appointment?.technicianAppointment?.technician?.poolOfTechnician?.id">(pool)</span>
                      </div>
                      <div v-else>
                        <span class="badge badge-pill badge-danger">To be assign</span>
                      </div>

                    </td>
                    <td>
                      <span class="">
                        {{ appointment?.requested_time_interval }} Hours
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-pill"
                            :class="appointmentStatusBadgeColour(appointment.status)">
                        {{ appointment.status }}
                      </span>
                    </td>
                    <td class="text-right">
                      <div>
                        Total: ${{ getAppointmentPrice(appointment?.appointmentCharges ?? []) }}
                      </div>
                      <div
                          v-bind:style="{cursor: 'pointer'}"
                          v-tooltip="{
                            text:`Card Charges: $`+ getAppointmentCardSurcharge(appointment?.appointmentPayments ?? []),
                            displayArrow:true,
                            theme:{
                              color:'#000000',
                              placement:'bottom',
                              border: '1px solid red',
                              'background-color':'#FFF1DF'
                            }}">
                        Paid:
                        ${{
                          parseFloat(parseFloat(getAppointmentPaidPrice(appointment?.appointmentPayments ?? [])) + parseFloat(getAppointmentCardSurcharge(appointment?.appointmentPayments ?? []))).toFixed(2)
                        }}
                      </div>
                      <div v-if="appointment?.appointmentRefundsByReference?.length > 0">
                        Refund: ${{ getAppointmentRefund(appointment.appointmentRefundsByReference) }}
                      </div>
                      <div class="text-danger"
                           v-if="getAppointmentPrice(appointment.appointmentCharges) - getAppointmentPaidPrice(appointment?.appointmentPayments) > 0">
                        Remaining: ${{
                          parseFloat(getAppointmentPrice(appointment.appointmentCharges) - getAppointmentPaidPrice(appointment?.appointmentPayments)).toFixed(2)
                        }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start justify-content-end">
                        <button v-if="appointment.appointmentNotes.length > 1"
                                :disabled="!can('appointment-note-view-any')"
                                :title="can('appointment-note-view-any') ? 'View Notes' : 'View  Note Forbidden'"
                                @click="getSingleAppointmentNotes(appointment.id)"
                                class="cursor-pointer view-edit-delete-icon bg-transparent text-decoration-none border-0"
                                :style="{height:'18px'}">
                          <i class='bx bx-note text-danger'></i>
                        </button>
                        <button :disabled="!can('appointment-view')"
                                :title="can('appointment-view') ? 'View Appointment' : 'View Forbidden'"
                                @click="viewAppointmentModalOpenHandler(appointment.id)"
                                class="cursor-pointer view-edit-delete-icon bg-transparent text-decoration-none border-0"
                                :style="{height:'18px'}">
                          <i class='bx bx-show'></i>
                        </button>
                        <!-- start three dot list -->
                        <div class="dropdown">
                          <span type="button" class="px-0 py-0 view-edit-delete-icon"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                              <i class="bx bx-dots-vertical-rounded h-100"></i>
                          </span>

                          <div v-if="appointment.status != 'Undecided'" class="dropdown-menu">
                            <button :title="can('appointment-view-any') ? 'View History' : 'View Forbidden'"
                                    :disabled="!can('appointment-view-any')" class="dropdown-item"
                                    @click="getSingleAppointmentHistories(appointment.id)">
                              History
                            </button>
                            <button :title="can('appointment-delete') ? 'Delete Appointment' : 'View Forbidden'"
                                    :disabled="!can('appointment-delete')" class="dropdown-item"
                                    @click="deleteAlertModalOpen(appointment.id)">
                              Delete
                            </button>
                            <button
                                :title="can('appointment-assign-technician') ? 'Assign Technician' : 'Assign Technician Forbidden'"
                                :disabled="!can('appointment-assign-technician')"
                                v-if="appointment.technicianAppointment == null" class="dropdown-item"
                                @click="openAssignTechnicianModal(appointment.id)">
                              Assign Technician
                            </button>
                            <button
                                :title="canAll(['appointment-status-update','appointment-update']) ? 'Update Status' : 'Update Status Forbidden'"
                                :disabled="!canAll(['appointment-status-update','appointment-update'])"
                                class="dropdown-item" @click="openUpdateAppointmentStatusModal(appointment.id)">
                              Update Status
                            </button>
                            <button
                                :title="canAny(['card-payment-create','cash-payment-create','eft-payment-create']) ? 'Payment Create' : 'Payment Forbidden'"
                                :disabled="!canAny(['card-payment-create','cash-payment-create','eft-payment-create'])"
                                v-if="( parseFloat(getAppointmentPrice(appointment.appointmentCharges)) >  (parseFloat(getAppointmentPaidPrice(appointment?.appointmentPayments)))  )"
                                class="dropdown-item" @click="makePaymentModalOpen(appointment.id)">
                              Make Payment
                            </button>
                            <button v-if="appointment.status === 'Delivered' || appointment.status === 'Closed'"
                                    :disabled="!can('appointment-invoice-view')" class="dropdown-item p-0">
                              <router-link
                                  :title="can('appointment-invoice-view') ? 'View Invoice' : 'Invoice View Forbidden'"
                                  :to="{params:{uuid:appointment.uuid},name: 'appAppointmentInvoice' }"
                                  class="dropdown-item">
                                View Invoice
                              </router-link>
                            </button>
                            <button @click="sendInvoiceEmail(appointment.id)"
                                    v-if="appointment.status === 'Delivered' || appointment.status === 'Closed'"
                                    class="dropdown-item">
                              Send Invoice Email
                            </button>
                            <button @click="sendPaymentRequestEmailToCustomerModalOpenHandler(appointment.id)"
                                    v-if="( parseFloat(getAppointmentPrice(appointment.appointmentCharges)) >  (parseFloat(getAppointmentPaidPrice(appointment?.appointmentPayments)))  )"
                                    class="dropdown-item">
                              Send Payment Request Email
                            </button>
                            <button :disabled="!can('order-create')" class="dropdown-item p-0">
                              <router-link :title="can('order-create') ? 'Create Order' : 'Order Create Forbidden'"
                                           v-if="appointment.order === null"
                                           :to="{params:{customerId: appointment.customer.id, appointmentData:JSON.stringify({id: appointment.id,shippingAddressId: appointment.address.id,billingAddressId: appointment.billingAddress.id})},name: 'appOrderCreate' }"
                                           class="dropdown-item">
                                Create Order
                              </router-link>
                            </button>
                            <button :title="can('appointment-note-view-any') ? 'View Notes' : 'View Note Forbidden'"
                                    :disabled="!can('appointment-note-view-any')" class="dropdown-item"
                                    @click="getSingleAppointmentNotes(appointment.id)">
                              View notes
                            </button>
                            <button :title="can('appointment-note-create') ? 'Create Note' : 'Create Note Forbidden'"
                                    :disabled="!can('appointment-note-create')" class="dropdown-item"
                                    data-toggle="modal" data-target="#AdminNoteCreateModal"
                                    @click="addNote( appointment.id, appointment.customer.user.id )">
                              Add note
                            </button>

                            <button
                                :title="canAll(['appointment-status-update','appointment-update']) ? 'Update Service Address' : 'Update Service Address Forbidden'"
                                :disabled="!canAll(['appointment-status-update','appointment-update'])"
                                class="dropdown-item"
                                @click="addServiceAddress(appointment.id)"
                                v-if="!(appointment.status === 'Delivered' || appointment.status === 'Closed')">
                              Update Service Address
                            </button>
                            <button
                                v-if="( parseFloat(getAppointmentPrice(appointment.appointmentCharges)) >  (parseFloat(getAppointmentPaidPrice(appointment?.appointmentPayments))) )"
                                class="dropdown-item" @click="addDiscount( appointment)">
                              Add discount
                            </button>
                            <button
                                v-if="appointment.status === 'Open'"
                                :title="can('send-push-notification') ? 'Push to Pools' : 'Push to Pools Forbidden'"
                                :disabled="!can('send-push-notification')"
                                class="dropdown-item" @click="addPoolTechnician(appointment.id)">
                              Push to Pools
                            </button>
                            <button
                                :title="canAll(['appointment-status-update','appointment-update']) ? 'Admin Task' : 'Admin Task Forbidden'"
                                :disabled="!canAll(['appointment-status-update','appointment-update'])"
                                class="dropdown-item"
                                @click="addAdminTask(appointment.id)"
                                v-if="(appointment.status === 'Open')">
                              {{ appointment?.adminTask === null ? 'Add' : 'Update' }} Admin Task
                            </button>

                            <button
                                :title="can('appointment-view-any') ? 'Payment Collection' : 'Payment Collection Forbidden'"
                                :disabled="!can('appointment-view-any')" class="dropdown-item"
                                v-if="isUnpaidAppointmentPaymentCollectionModalShow(appointment)"
                                @click="showPaymentCollectionModal(appointment.id)">
                              Payment Collection
                            </button>

                            <button v-if="appointment && appointment.order !==  null"
                                    @click="getConsolidatedInvoice(appointment)" class="dropdown-item"
                                    :disabled="!can('appointment-invoice-view')"
                                    :title="can('appointment-invoice-view') ? 'View' : 'View Forbidden'">
                              Consolidate Invoice
                            </button>

                          </div>

                          <div v-else class="dropdown-menu">
                            <button :title="can('appointment-update') ? 'Update Status' : 'Update Status Forbidden'"
                                    :disabled="!can('appointment-update')" class="dropdown-item"
                                    @click="openUpdateAppointmentStatusModalForUndecided(appointment.id)">
                              Change Status
                            </button>
                          </div>
                        </div>
                        <!-- end three dot list -->
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyAppointmentFilter"/>
              </div>
            </div>
          </div>
        </div>
        <div class="" data-toggle="modal" data-target="#appointmentQuickViewModal"></div>
        <div class="" data-toggle="modal" data-target="#ViewNotesModal"></div>
        <div class="" data-toggle="modal" data-target="#appointmentHistoryModal"></div>
        <div class="" data-toggle="modal" data-target="#AppointmentStatusUpdateModal"></div>
        <div class="" data-toggle="modal" data-target="#AppointmentInvoiceModal"></div>
        <div class="" data-toggle="modal" data-target="#appointmentDeleteAlertModal"></div>
        <div class="" data-toggle="modal" data-target="#appointmentPaymentModal"></div>
        <div class="" data-toggle="modal" data-target="#appointmentViewModal"></div>
        <div class="" data-toggle="modal" data-target="#assignTechnicianModal"></div>
        <div class="" data-toggle="modal" data-target="#appointmentRemainingPaymentRequestNotifyEmailSendModal"></div>
        <div class="" data-toggle="modal" data-target="#orderDetailsModal"></div>
        <div class="" data-toggle="modal" data-target="#addDiscountModal"></div>
        <div class="" data-toggle="modal" data-target="#userServiceAddressUpdateModal"></div>
        <div class="" data-toggle="modal" data-target="#assignAdminTaskModal"></div>
        <div class="" data-toggle="modal" data-target="#assignPoolTechnicianModal"></div>
        <div class="" data-toggle="modal" data-target="#appointmentStatusUpdateModalForUndecided"></div>
        <div class="" data-toggle="modal" data-target="#paymentCollectionModal"></div>


        <!-- modal -->
        <ViewNotesModal/>
        <AppointmentHistoryModal/>
        <AdminNoteCreateModal :selectedAppointmentId="selectedAppointmentId" :selectedUserId="selectedUserId"
                              :userAllType="userType" :appointmentAllNoteType="appointmentNoteTypes"/>

        <AppointmentQuickViewModal :appointment="{ ...appointment }"/>
        <AppointmentViewModal :appointment="{ ...appointment }"/>
        <AppointmentStatusUpdateModal @getAppointmentList="getAppointmentList" :appointment="{...appointment }"
                                      :userAllTypes="userType" :appointmentParams="{...getAppointmentsParams}"/>
        <AppointmentPayment @getAppointmentList="getAppointmentList" :appointment="{...appointment }"
                            :userAllTypes="userType" :appointmentParams="{...getAppointmentsParams}"/>
        <AppointmentInvoiceModal :appointment="{ ...appointment }"/>
        <AppointmentDeleteAlertModal :model-id="appointment.id" model-name="appointment"
                                     modal-name="appointmentDeleteAlertModal"
                                     @confirmModelDeletion="confirmModelDeletionAction"/>
        <AssignTechnicianModal @getAppointmentList="getAppointmentList" :appointment="{ ...appointment }"
                               :appointmentParams="{...getAppointmentsParams}"/>
        <AppointmentRemainingPaymentRequestSendModal :appointment="{ ...appointment }"/>
        <AddDiscountModal :appointment="{ ...appointment }" @getAppointmentList="getAppointmentList"/>
        <OrderDetailsModal/>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        <UserAddressCreateOnlyModal ref="serviceAddress" :model-primary-address-id="setAddressIdForServiceAddress"
                                    :user-id="setUserIdForServiceAddress"
                                    :business-status="setCustomerTypeStatusForServiceAddress"
                                    modal-name="userServiceAddressUpdateModal"
                                    @emitUserAddress="updateServiceAddressData"/>
        <AssignAdminTaskModal :appointment="{...appointment}"></AssignAdminTaskModal>
        <AssignPoolTechnicianModal ref="poolTechnicianModal" @getAppointmentList="getAppointmentList"
                                   :appointment="{...appointment}"
                                   :appointmentParams="{...getAppointmentsParams}"></AssignPoolTechnicianModal>
        <AppointmentStatusUpdateModalForUndecided @getAppointmentList="getAppointmentList"
                                                  :appointment="{...appointment }"
                                                  :userAllTypes="userType"
                                                  :appointmentParams="{...getAppointmentsParams}"/>
        <payment-collection-modal :appointment="{...appointment}"
                                  :appointment-params="{...getAppointmentParams}" @getAppointmentList="getAppointmentList"></payment-collection-modal>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import {
  DatePicker
} from 'v-calendar';
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AdminNoteCreateModal from "@/views/backEnd/appointments/appointmentList/includes/AdminNoteCreateModal";
import ViewNotesModal from "@/views/backEnd/appointments/appointmentList/includes/ViewNotesModal";
import AppointmentInvoiceModal from "@/views/backEnd/appointments/appointmentList/includes/AppointmentInvoiceModal";
import AppointmentDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
import Authorization from "@/components/backEnd/mixins/Authorization";
import {mapActions, mapGetters} from "vuex";
import AppointmentQuickViewModal from "@/views/backEnd/appointments/appointmentList/includes/AppointmentQuickViewModal";
import AppointmentViewModal from "@/views/backEnd/appointments/appointmentList/includes/AppointmentViewModal";
import AppointmentHistoryModal from "@/views/backEnd/appointments/appointmentList/includes/AppointmentHistoryModal";
import AppointmentPayment from "@/views/backEnd/appointments/appointmentList/includes/AppointmentPayment";
import AppointmentStatusUpdateModal
  from "@/views/backEnd/appointments/appointmentList/includes/AppointmentStatusUpdateModal";
import AssignTechnicianModal from "@/views/backEnd/appointments/appointmentList/includes/AssignTechnicianModal";
import AppointmentRemainingPaymentRequestSendModal
  from "@/views/backEnd/appointments/appointmentList/includes/AppointmentRemainingPaymentRequestSendModal";
import OrderDetailsModal from "@/views/backEnd/appointments/appointmentList/includes/OrderDetailsModal.vue";
import AddDiscountModal from "@/views/backEnd/appointments/appointmentList/includes/AddDiscountModal.vue";
import ConfirmDialogue from "@/components/backEnd/modal/ConfirmDialogue";
import UserAddressCreateOnlyModal from "@/components/backEnd/modal/UserAddressCreateOnlyModal.vue";
import AssignAdminTaskModal from "@/views/backEnd/appointments/appointmentList/includes/AssignAdminTaskModal.vue";
import AssignPoolTechnicianModal
  from "@/views/backEnd/appointments/appointmentList/includes/AssignPoolTechnicianModal.vue";
import AppointmentStatusUpdateModalForUndecided
  from "@/views/backEnd/appointments/appointmentList/includes/AppointmentStatusUpdateModalForUndecided.vue";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";
import PaymentCollectionModal from "@/views/backEnd/appointments/appointmentList/includes/PaymentCollectionModal.vue";

export default {
  name: "AppointmentList",
  mixins: [ShowToastMessage, Loader, Authorization, AddressMixin],
  components: {
    PaymentCollectionModal,
    AssignPoolTechnicianModal,
    AssignAdminTaskModal,
    UserAddressCreateOnlyModal,
    ConfirmDialogue,
    AppointmentRemainingPaymentRequestSendModal,
    AssignTechnicianModal,
    AppointmentStatusUpdateModal,
    AppointmentHistoryModal,
    AppointmentQuickViewModal,
    DatePicker,
    AdminNoteCreateModal,
    AppLayout,
    AppointmentInvoiceModal,
    AppointmentDeleteAlertModal,
    AppointmentPayment,
    ViewNotesModal,
    AppointmentViewModal,
    ListPagination,
    OrderDetailsModal,
    AddDiscountModal,
    AppointmentStatusUpdateModalForUndecided,
  },
  data() {
    return {
      panelOptions: [
        // 0:Online Appointment Form, 1:System Web-App, 2:Technician Mob-App, 3:Customer Web-App, 4:Customer Mob-App 5: PartNer Mob-App	
        {
          name: "Any",
          value: "",
        },
        {
          name: "Online Appointment",
          value: 0,
        },
        {
          name: "System Web-App",
          value: 1,
        },
        {
          name: "Technician Mob-App",
          value: 2,
        },
        {
          name: "Customer Web-App",
          value: 3,
        },
        {
          name: "Customer Mob-App",
          value: 4,
        },
        {
          name: "Partner Mob-App",
          value: 5,

        }
      ],
      serviceDateRange: {
        start: this.currentDate(),
        end: this.currentDate(),
      },
      creationDateRange: {
        start: "",
        end: "",
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      getSettingsParams: {
        type: ["default", "appointment", "payment"],
        key: [
          "default_pagination",
          "default_state",
          "default_customer_type",
          "appointment_note_type",
          "appointment_status",
          "payment_card_surcharge",
          "appointment_service_duration",
          "default_appointment_payment_collection_stage_template"
        ],
      },
      getUserTechniciansParams: {
        where_has_technician_status: 1,
        with_relation: ['technician'],
        order_by_first_name: "ASC",
      },
      getAppointmentsParams: {
        customer_id: '',
        service_id: '',
        reference: '',
        type: '',
        status: '',
        date: [],
        time: [],
        created_at: [],
        where_has_customer_user_or_customer_address_query: '',
        where_has_technician_id: '',
        where_has_franchisee_id: '',
        where_has_address_state: '',
        where_has_panel: '',
        with_relation: [
          "address",
          "order",
          "customer.user",
          "customer.address.business",
          "billingAddress",
          "service",
          "appointmentCharges",
          "appointmentNotes",
          "appointmentCreator.user",
          "appointmentConsents",
          "appointmentPayments.payment.cardPayment",
          "appointmentPayments.payment.afterpayPayment",
          "technicianAppointment.technician.user",
          "appointmentRefundsByReference",
          "appointmentSchedules",
          "adminTask",
          "appointmentPaymentCollection",
          "technicianAppointment.technician.poolOfTechnician",
        ],
        order_by_time: 'ASC',
        order_by_date: 'ASC',
        paginate: 1,
        pagination: '',
        page: '',
      },

      selectedPagination: {
        value: '',
        name: "Default",
      },

      selectedState: {
        value: '',
        name: "Any",
      },
      selectedPanel: {
        value: '',
        name: "Any",

      },
      selectedAppointmentType: {
        value: '',
        name: "Any",
      },

      selectedStatus: {
        value: '',
        name: "Any",
      },

      selectedFromDate: this.currentDate,

      selectedToDate: this.currentDate,

      selectedService: {
        value: '',
        name: "Any"
      },

      selectedTimeRange: {
        value: [],
        name: "Any"
      },

      selectedTechnician: {
        value: '',
        name: "Any"
      },

      getServicesParams: {
        order_by_name: 'ASC',
      },

      appointment: {id: ''},

      getAppointmentParams: {
        with_relation: [
          "customer",
          "order",
          "service",
          "appointmentCharges",
          "appointmentNotes",
          "appointmentCreator",
          "customer.address",
          "customer.user",
          "appointmentPayments.payment",
          "technicianAppointment.technician.user",
        ],
      },
      getAppointmentNotesParams: {
        appointment_id: '',
        with_relation: [
          "user",
          "appointment"
        ],
      },
      getAppointmentHistoriesParams: {
        appointment_id: '',
        with_relation: [
          "user",
          "appointment",
          "technician.user"
        ],
      },

      // when we select on a specific appointment
      selectedAppointmentId: "",
      selectedUserId: "",
      setUserIdForServiceAddress: "",
      setAddressIdForServiceAddress: "",
      setCustomerTypeStatusForServiceAddress: false,
    };
  },
  computed: {
    ...mapGetters({
      authFranchisee: 'appAuthLogin/authFranchisee',
      appointments: "appAppointments/appointments",
      availableTechnicians: 'appTechnicians/availableTechnicians',
      userType: "appUsers/userType",
      services: 'appService/services',
      paginateLinks: "appAppointments/paginateLinks",
      settingPaymentCardSurcharge: 'appSettings/settingPaymentCardSurcharge',

    }),


    paginationOptions() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    stateOptions() {
      return [{value: null, name: "Any"}, ...this.$store.getters["appSettings/settingDefaultState"].value];
    },

    appointmentTypeOptions() {
      return [
        {value: '', name: "Any"},
        {value: 0, name: 'Home'},
        {value: 1, name: 'Business'},
      ];
    },

    appointmentStatusOptions() {
      return [{value: '', name: "Any"}, ...this.$store.getters["appSettings/settingAppointmentStatus"].value];
    },

    timeRangeOptions() {
      return [
        {value: [], name: 'Any'},
        {value: ['08:00', '09:00'], name: '08:00 AM - 09:00 AM'},
        {value: ['10:00', '11:00'], name: '10:00 AM - 11:00 AM'},
        {value: ['12:00', '13:00'], name: '12:00 PM - 01:00 PM'},
        {value: ['14:00', '15:00'], name: '02:00 PM - 03:00 PM'},
        {value: ['16:00', '17:00'], name: '04:00 PM - 05:00 PM'},
        {value: ['18:00', '19:00'], name: '06:00 PM - 07:00 PM'},
        {value: ['20:00', '21:00'], name: '08:00 PM - 09:00 PM'},
      ];
    },

    serviceOptions() {
      return [{
        id: null,
        name: "Any",
      },
        ...this.$store.getters["appService/services"],
      ];
    },

    technicianOptions() {
      let technicians = this.$store.getters['appUsers/users'].map(item => {
        let technicianId = item.technician && item.technician.id ? item.technician.id : '';
        let firstName = item.first_name;
        let lastName = item.last_name;
        let fullName = firstName;
        fullName += firstName ? ` ${lastName}` : lastName;
        return {
          value: technicianId,
          name: fullName,
        };
      })
      return [{value: '', name: "Any"}, ...technicians];
    },

    appointmentNoteTypes() {
      return this.$store.getters["appSettings/settingAppointmentNoteType"].value;
    },

  },

  watch: {
    selectedPagination(selectedPagination) {
      this.getAppointmentsParams.pagination = selectedPagination.value;
    },

    selectedState(selectedState) {
      this.getAppointmentsParams.where_has_address_state = selectedState.value;
    },

    selectedAppointmentType(selectedAppointmentType) {
      this.getAppointmentsParams.type = selectedAppointmentType.value;
    },

    selectedStatus(selectedStatus) {
      this.getAppointmentsParams.status = selectedStatus.value;
    },

    // selectedFromDate(selectedFromDate) {
    //   this.getAppointmentsParams.date[0] = selectedFromDate;
    // },

    // selectedToDate(selectedToDate) {
    //   this.getAppointmentsParams.date[1] = selectedToDate;
    // },
    serviceDateRange(selectedServiceDateRange) {
      if (selectedServiceDateRange?.start?.length) {
        this.getAppointmentsParams.date[0] = selectedServiceDateRange.start;
        this.getAppointmentsParams.date[1] = selectedServiceDateRange.end;

        this.creationDateRange = {};
        this.getAppointmentsParams.created_at = [];
      }

    },
    creationDateRange(selectedCreationDateRange) {
      if (selectedCreationDateRange?.start?.length) {
        this.serviceDateRange = {};
        this.getAppointmentsParams.date = [];


        this.getAppointmentsParams.created_at[0] = selectedCreationDateRange.start;
        this.getAppointmentsParams.created_at[1] = selectedCreationDateRange.end;

      }

    },

    selectedTimeRange(selectedTimeRange) {
      this.getAppointmentsParams.time = selectedTimeRange.value;
    },

    selectedService(selectedService) {
      this.getAppointmentsParams.service_id = selectedService.value;
    },

    selectedTechnician(selectedTechnician) {
      this.getAppointmentsParams.where_has_technician_id = selectedTechnician.value;
    },
    selectedPanel(selectedPanel) {
      this.getAppointmentsParams.where_has_panel = selectedPanel.value;
    },
  },

  methods: {
    ...mapActions({
      resetAppointments: "appAppointments/resetAppointments",
      getAppointments: "appAppointments/getAppointments",
      getAppointment: "appAppointments/getAppointment",
      getUsers: 'appUsers/getUsers',
      getUserType: "appUsers/getUserType",
      getAppointmentNotes: 'appAppointments/getAppointmentNotes',
      getAppointmentHistories: 'appAppointments/getAppointmentHistories',
      deleteAppointmentOnList: "appAppointments/deleteAppointmentOnList",
      postAppointmentInvoiceRequestNotifyEmail: "appAppointments/postAppointmentInvoiceRequestNotifyEmail",
      getAvailableTechnicians: 'appTechnicians/getAvailableTechnicians',
      getSettings: "appSettings/getSettings",
      getServices: 'appService/getServices',
      getOrder: 'appOrders/getOrder',
      updateAppointment: 'appAppointments/putAppointment',
    }),
    async getConsolidatedInvoice(appointment) {
      const appUUID = appointment.uuid;
      const ordUUID = appointment?.order?.uuid;
      if(appUUID && ordUUID){
        const routeData = this.$router.resolve({
          name: 'appConsolidateInvoice',
          params: {
            uuid: `${appUUID}&${ordUUID}`
          }
        });
        window.open(routeData.href, '_blank');
      }
    },

    isUnpaidAppointmentPaymentCollectionModalShow(appointment) {
      let paymentExist = Boolean(appointment?.appointmentPayments?.length ?? 0);
      let cancelStatus = appointment?.status === 'Cancelled';
      let collectionCreated = appointment?.appointmentPaymentCollection?.status === 'Yes';
      return (!paymentExist && cancelStatus && collectionCreated);
    },
    // on click open payment collection modal
    async showPaymentCollectionModal(appointmentId) {
      this.findAppointment(appointmentId);
      await this.$nextTick(() => {
        document.querySelector('[data-target="#paymentCollectionModal"]').click();
      });
    },
    // on click set variable for assign pool technician task
    async addPoolTechnician(appointmentId) {
      await this.findAppointment(appointmentId);
      await this.$nextTick(() => {
        document.querySelector('[data-target="#assignPoolTechnicianModal"]').click();
      });
    },
    // on click set variable for assign admin task
    async addAdminTask(appointmentId) {
      await this.findAppointment(appointmentId);
      await this.$nextTick(() => {
        document.querySelector('[data-target="#assignAdminTaskModal"]').click();
      });
    },
    // on click set variable for update service address
    async addServiceAddress(appointmentId) {
      this.setAddressIdForServiceAddress = '';
      this.setUserIdForServiceAddress = '';
      this.setCustomerTypeStatusForServiceAddress = false;
      await this.$refs.serviceAddress.resetData();
      await this.findAppointment(appointmentId);
      this.setAddressIdForServiceAddress = this.appointment?.address?.id ?? '';
      this.setUserIdForServiceAddress = this.appointment?.customer?.user?.id ?? '';
      this.setCustomerTypeStatusForServiceAddress = Boolean(this.appointment?.customer?.address?.business?.id);

      await this.$nextTick(() => {
        document.querySelector('[data-target="#userServiceAddressUpdateModal"]').click();
      });

    },

    // after update address
    async updateServiceAddressData(dataObject) {
      this.setAddressIdForServiceAddress = '';
      this.setUserIdForServiceAddress = '';
      this.setCustomerTypeStatusForServiceAddress = false;
      dataObject['addressData']['post_code'] = dataObject.addressData.postCode;
      // let data = {...dataObject.addressData, post_code: dataObject.addressData.postCode}

      await this.updateSingleAppointment(this.appointment.id, dataObject?.addressData?.id);
      let index = this.appointments.findIndex(item => item.id === this.appointment.id);
      if (index !== -1) {
        this.appointments[index].address = dataObject.addressData;
      }
    },

    // call update appointment api
    async updateSingleAppointment(id, addressId) {
      let dataObj = {
        id: id,
        data: {address_id: addressId},
      }
      this.loader(true);
      await this.updateAppointment(dataObj).then(async (response) => {
        this.loader(false);
        if (response.status === 200) {
          const toastObj = {
            message: "Appointment service address updated successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);

        }
        this.showToastMessage(response);
      });
    },

    // calculate card surcharge
    getAppointmentCardSurcharge(appointmentPayments) {

      let cardSurcharge = 0;
      appointmentPayments?.forEach((item) => {
        if (item?.payment?.type === 'Card') {
          cardSurcharge += item?.payment?.cardPayment?.card_surcharge ?? 0;
        }
        if (item?.payment?.type === 'Afterpay') {
          cardSurcharge += item?.payment?.afterpayPayment?.card_surcharge ?? 0;
        }
      });
      return parseFloat(cardSurcharge / 100).toFixed(2);
    },

    currentDate() {
      const today = new Date();
      let year = today.getFullYear();
      let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
      let date = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      return year + "-" + month + "-" + date;
    },

    buildFullAddress(addressObj) {
      return `${addressObj?.street ?? ''}, ${addressObj?.suburb ?? ''} ${addressObj?.state?.toUpperCase() ?? ''} ${addressObj?.post_code ?? ''}, ${addressObj?.country ?? ''}`
    },

    // for 02:00 PM type format
    convertTime12To24Format(timeString) {
      const [time, modifier] = timeString.split(' ');
      let [hours, minutes] = time.split(':');
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      return `${hours}:${minutes}`;
    },

    async getUserTechnicianList() {
      await this.getUsers(this.getUserTechniciansParams).then(async response => {
        if (response.status !== 200) {
          await this.showToastMessage(response.message);
        }
      });
    },

    appointmentStatusBadgeColour(status) {
      if (status === 'Open')
        return 'badge-light-warning';
      else if (status === 'Cancelled')
        return 'badge-light-danger';
      else if (status === 'Closed')
        return 'badge-light-success';
      else if (status === 'Credited')
        return 'badge-light-primary';
      else if (status === 'Delivered')
        return 'badge-light-success';
      else if (status === 'Dispute')
        return 'badge-light-danger';
      else if (status === 'Hold')
        return 'badge-light-danger';
      else if (status === 'Reassigned')
        return 'badge-light-info';
      else if (status === 'Refund')
        return 'badge-light-secondary';
      else if (status === 'Rescheduled')
        return 'badge-light-info';
      else if (status === 'Rescheduled & Reassigned')
        return 'badge-light-info';
      else
        return 'badge-light-secondary';
    },

    getAppointmentPrice(appointmentCharges) {

      let total = 0;
      appointmentCharges.map((item) => {
        if (item.type === "GST")
          total += item.amount;
        else if (item.type === "Service Price")
          total += item.amount;
        else if (item.type === "Surcharge")
          total += item.amount;
        else
          total -= item.amount;
      });

      total = parseFloat(total / 100).toFixed(2);
      return total;
    },

    getAppointmentPaidPrice(appointmentPayments) {

      let paidAmount = 0;
      appointmentPayments.map((item) => {
        paidAmount += item.payment.total_transaction;
      });

      paidAmount = parseFloat(paidAmount / 100).toFixed(2);
      return paidAmount;
    },

    getAppointmentRefund(appointmentRefundPayments) {
      if (appointmentRefundPayments.length > 0) {
        const amount = appointmentRefundPayments.filter((appointmentRefund) => appointmentRefund.status === 'Refunded').reduce((previousValue, refundObj) => previousValue + refundObj.amount, 0);

        return parseFloat(amount / 100).toFixed(2);
      }
      return 0;
    },

    findAppointment(id) {
      this.appointment = {};
      this.appointment = this.appointments.find((appointment) => appointment.id === id);
    },

    deleteAlertModalOpen(id) {
      this.findAppointment(id);
      document.querySelector('[data-target="#appointmentDeleteAlertModal"]').click();
    },

    viewAppointmentModalOpenHandler(id) {
      this.findAppointment(id);
      document.querySelector('[data-target="#appointmentViewModal"]').click();
    },

    makePaymentModalOpen(id) {
      this.findAppointment(id);
      document.querySelector('[data-target="#appointmentPaymentModal"]').click();

    },
    addDiscount(appointment) {

      this.appointment = {};
      // this.appointment = appointment;
      this.appointment = {
        ...appointment,
        remainingAmount: parseFloat(this.getAppointmentPrice(appointment.appointmentCharges)) - parseFloat(this.getAppointmentPaidPrice(appointment?.appointmentPayments))
      };
      document.querySelector('[data-target="#addDiscountModal"]').click();
      // parseFloat(getAppointmentPrice(appointment.appointmentCharges)) >  (parseFloat(getAppointmentPaidPrice(appointment?.appointmentPayments)))


    },

    async openOrderDetailsModal(orderId) {
      await this.getSingleOrder(orderId);
    },
    async getSingleOrder(orderId) {
      let paramObj = {
        id: orderId,
        params: {
          with_relation: [
            'user',
            'user.customer.address',
            'user.customer.address.business',
            'orderCharges',
            'orderPayments.payment',
            'orderPaymentsByReference',
            'appointment.technicianAppointment.technician.user',
            'shippingAddress.business',
            'billingAddress.business',
            'orderNotes',
            'orderProductItems.productItem.product'
          ],
        },
      };
      this.loader(true);
      const response = await this.getOrder(paramObj);
      this.loader(false);
      if (response && response.message && response.status !== 200) {
        this.showToastMessage(response);
        return;
      }
      document.querySelector('[data-target="#orderDetailsModal"]').click();
    },

    async openAssignTechnicianModal(id) {
      await this.findAppointment(id);
      if (this.appointment.technicianAppointment == null) {
        await this.getTechnicians({
          requestedDate: this.appointment.date,
          requestedTimeSlot: this.convertTime12To24Format(this.appointment.time), //for api request time format (H:M)
          state: this.appointment.address.state,
          preference: this.appointment.preference === "Remote" ? 1 : 0,
          destinationAddress: this.buildFullAddress(this.appointment.address),
        });

      }

    },
    async getTechnicians(getTechniciansParamData) {
      let time = getTechniciansParamData.requestedTimeSlot.split(':');
      if (time.length > 2) {
        getTechniciansParamData.requestedTimeSlot = `${time[0]}:${time[1]}`
      }
      this.loader(true);

      const data = {
        requested_date: getTechniciansParamData.requestedDate,
        requested_time_slot: getTechniciansParamData.requestedTimeSlot,
        state: getTechniciansParamData.state,
        preference: getTechniciansParamData.preference,
        destination_address: getTechniciansParamData.destinationAddress,
      }

      const response = await this.getAvailableTechnicians(data);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200) {

        this.loader(false);
        if (this.availableTechnicians.status === false) {
          this.showToastMessage({
            message: this.availableTechnicians.message,
            type: "error"
          });
          return;
        }
        document.querySelector('[data-target="#assignTechnicianModal"]').click();

      } else {
        this.loader(false);

      }

    },
    openUpdateAppointmentStatusModal(id) {
      this.findAppointment(id);
      if (this.appointment.status === 'Cancelled') {
        this.showToastMessage({
          type: 'error',
          message: `You can't update the status for this appointment.`
        })
        return;
      }
      document.querySelector('[data-target="#AppointmentStatusUpdateModal"]').click();
    },
    openUpdateAppointmentStatusModalForUndecided(id) {
      this.findAppointment(id);
      if (this.appointment.status === 'Cancelled') {
        this.showToastMessage({
          type: 'error',
          message: `You can't update the status for this appointment.`
        })
        return;
      }
      document.querySelector('[data-target="#appointmentStatusUpdateModalForUndecided"]').click();
    },
    sendPaymentRequestEmailToCustomerModalOpenHandler(id) {
      this.findAppointment(id);
      document.querySelector('[data-target="#appointmentRemainingPaymentRequestNotifyEmailSendModal"]').click();
    },


    async deleteSingleAppointmentOnLIst(id) {
      this.loader(true);
      this.deleteAppointmentOnList(id).then(async (response) => {
        this.loader(false);
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Appointment deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);

        }
        this.showToastMessage(response);
      });
    },

    async confirmModelDeletionAction(confirmModelDeletion) {
      await this.deleteSingleAppointmentOnLIst(confirmModelDeletion.modelId);
    },

    async getAppointmentList() {
      this.loader(true);
      await this.getAppointments(this.getAppointmentsParams).then((response) => {
        this.loader(false);
        if (response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSingleAppointment(id) {
      this.loader(true);
      const paramObj = {
        id: id,
        params: this.getAppointmentParams,
      };
      await this.getAppointment(paramObj).then((response) => {
        this.loader(false);
        if (response && response.status && response.status === 200) {
          document.querySelector('[data-target="#appointmentQuickViewModal"]').click();
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSingleAppointmentNotes(id) {
      this.loader(true);
      this.getAppointmentNotesParams.appointment_id = id;
      const paramObj = {
        id: id,
        params: this.getAppointmentNotesParams,
      };
      await this.getAppointmentNotes(paramObj).then((response) => {
        this.loader(false);
        if (response.status && response.status === 200) {
          document.querySelector('[data-target="#ViewNotesModal"]').click();
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getSingleAppointmentHistories(id) {
      this.loader(true);
      this.getAppointmentHistoriesParams.appointment_id = id;
      const paramObj = {
        appointment_id: id,
        params: this.getAppointmentHistoriesParams,
      };
      await this.getAppointmentHistories(paramObj).then((response) => {
        this.loader(false);
        if (response && response.status && response.status === 200) {
          document.querySelector('[data-target="#appointmentHistoryModal"]').click();
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getUserTypes() {
      const paramObj = {
        id: this.$store.getters["appAuthLogin/authUser"].id,
      };
      await this.getUserType(paramObj.id);
    },

    async addNote(appointmentId, userId) {
      this.selectedAppointmentId = appointmentId;
      this.selectedUserId = userId;
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async clearServiceDateRange() {
      this.getAppointmentsParams.date = [];
      this.serviceDateRange = {};
      await this.applyAppointmentFilter();
    },
    async clearCreationDateRange() {
      this.getAppointmentsParams.created_at = [];
      this.creationDateRange = {};
      await this.applyAppointmentFilter();
    },

    async applyAppointmentFilter(pageNumber) {
      if (this.$route.params.customerId) {
        this.getAppointmentsParams.customer_id = "";
      }

      if (this.getAppointmentsParams.reference.length) {
        this.getAppointmentsParams.service_id = '';
        this.selectedService = {value: '', name: "Any"};
        this.getAppointmentsParams.type = '';
        this.selectedAppointmentType = {value: '', name: "Any"};
        this.getAppointmentsParams.status = '';
        this.selectedStatus = {value: '', name: "Any"};

        this.getAppointmentsParams.date = [];
        this.serviceDateRange = {};

        this.getAppointmentsParams.created_at = [];
        this.creationDateRange = {};
        this.selectedPanel = {value: '', name: "Any"};
        this.getAppointmentsParams.where_has_panel = '';

        // this.selectedFromDate = '';
        // this.selectedToDate = '';

        // this.serviceDateRange.end = "";
        this.getAppointmentsParams.where_has_customer_user_or_customer_address_query = '';
        this.getAppointmentsParams.where_has_address_state = '';
        this.selectedState = {value: '', name: "Any"};


      }

      // if (this.serviceDateRange.start && this.serviceDateRange.end) {
      //   this.getAppointmentsParams.date = [this.serviceDateRange.start,  this.serviceDateRange.end]
      // }

      this.getAppointmentsParams.page = pageNumber;
      await this.getAppointmentList();
    },

    async getServiceList() {

      await this.getServices(this.getServicesParams);
    },

    async sendInvoiceEmail(appointmentId) {
      this.loader(true);
      let paramObj = {
        appointment: appointmentId,
        data: {
          notify_customer: 1,
          notify_internal_user: 1
        }
      }
      const response = await this.postAppointmentInvoiceRequestNotifyEmail(paramObj);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }

    },

  },

  async beforeMount() {
    await this.resetAppointments();
  },
  async mounted() {
    await this.loader(true);
    this.getAppointmentsParams.date[0] = this.currentDate();
    this.getAppointmentsParams.date[1] = this.currentDate();
    this.serviceDateRange.start = this.currentDate();
    this.serviceDateRange.end = this.currentDate();
    if (this.authFranchisee.id) {
      this.getAppointmentsParams.where_has_franchisee_id = this.authFranchisee.id;
    }
    if (this.$route.params.appointmentReference) {

      this.getAppointmentsParams.date = [];
      this.serviceDateRange = {};

      this.getAppointmentsParams.created_at = [];
      this.creationDateRange = {};

      this.getAppointmentsParams.reference = this.$route.params.appointmentReference;
    }
    if (this.$route.params.customerId) {

      this.getAppointmentsParams.date = [];
      this.serviceDateRange = {};

      this.getAppointmentsParams.created_at = [];
      this.creationDateRange = {};

      this.getAppointmentsParams.customer_id = this.$route.params.customerId;
    }
    await this.getSettingList();
    await this.getUserTechnicianList();
    await this.getAppointmentList();
    await this.getServiceList();
    await this.getUserTypes();
    await this.loader(false);
  },

};
</script>

<style scoped>
/* this class is for all table drop-down section overflow items to show */
.table-responsive {
  min-height: 80vh;
}

.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

</style>
